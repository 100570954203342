import moment from 'moment'

export default {
  methods: {
    formatSession: function (session) {
      console.log("updating session", session)
      console.log("session.virtualCamp", session.virtualCamp)
        let formatedSession = {}
        if(session.campSessionId) { 
          formatedSession.CampSessionId = session.campSessionId 
        }
        formatedSession.afterCare = session.afterCare ? 1 : 0
        formatedSession.startDate = moment(session.startDate).format('YYYY-MM-DD')
        formatedSession.endDate = moment(session.endDate).format('YYYY-MM-DD')
        formatedSession.afterCareEnd = session.afterCareEnd ? session.afterCareEnd : null
        formatedSession.ageOldest = session.ageOldest ? parseInt(session.ageOldest) : null
        formatedSession.ageYoungest = session.ageYoungest ? parseInt(session.ageYoungest) : null
        formatedSession.beforeCare = session.beforeCare ? 1 : 0
        formatedSession.beforeCareStart = session.beforeCareStart
        formatedSession.busService = session.bus ? 1 : 0
        formatedSession.campEndTime = session.campEndTime ? session.campEndTime : null
        formatedSession.campStartTime = session.campStartTime
        formatedSession.daysMeet = session.daysMeet
        formatedSession.draft = session.draft ? 1 : 0
        formatedSession.overnight = session.overnight ? 1 : 0
        formatedSession.sessionDescription = session.sessionDescription ? session.sessionDescription : ""
        formatedSession.title = session.title ? session.title : null
        formatedSession.categories =  session.categories,
        formatedSession.price = session.price ? parseInt(session.price) : null
        formatedSession.campId = session.campId ? session.campId : this.$store.state.campId
        formatedSession.campName = session.campName ? session.campName : this.$store.state.campName
        formatedSession.campLogo = session.campLogo ? session.campLogo : this.$store.state.campLogo
        formatedSession.campURL= session.campURL ? session.campURL : this.$store.state.campURL
        formatedSession.addressLine1 = this.session.addressLine1
        formatedSession.addressLine2 = this.session.addressLine2
        formatedSession.bookItUrl = this.session.bookItUrl
        formatedSession.city = this.session.city
        formatedSession.state = this.session.state
        formatedSession.zipcode = this.session.zipcode
        formatedSession.meals = this.session.meals ? 1 : 0
        formatedSession.isVirtual = session.isVirtual 
        return formatedSession
    }
  }
}