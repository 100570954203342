<template>
 <div class="login">
    <v-container fluid>
      <Navbar></Navbar>
      <div class="form">
        <v-row color="primary"> 
          <v-col :cols=1>
            <v-icon color="primary" class="ml-2" style="{ display: 'block' }" large depressed @click="$router.push('/camp')">mdi-chevron-left</v-icon>
          </v-col>
          <v-col :cols=11>
            <h1 class="primary--text">Add Sessions for {{campName}}</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols=6>
            <v-menu
              v-model="startDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y 
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  label="Start Date"
                  readonly
                  :value="startDate"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="en-in"
                v-model="startDate"
                no-title
                @input="startDateMenu = false"
                :min="minDate"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col :cols=6>
            <v-menu
              v-model="endDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y 
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  label="End Date"
                  readonly
                  :value="endDate"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="en-in"
                v-model="endDate"
                no-title
                @input="endDateMenu = false"
                :min="minDate"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <div>
          <v-form ref="sessionForm" @submit.prevent="">
            <v-row>
              <DaysWidget @updateSessionDays="updateSessionDays" :daysMeet="session.daysMeet"></DaysWidget>
            </v-row>
            <v-row>
              <v-col>
                <v-checkbox v-model="virtualCamp" label="No address (Virtual Camp)"></v-checkbox>
              </v-col>
              <v-col>
                <v-checkbox v-model="useCampAddress" label="Use my camp's address." :disabled="virtualCamp"></v-checkbox>
              </v-col>
            </v-row>
            <v-row v-show="!virtualCamp">
              <v-col :cols=6>
                <v-text-field 
                  v-model="session.addressLine1"
                  label="Session Address Line 1"
                  type="text"
                  :rules="[rules.addressLine1Rules]"
                ></v-text-field>
              </v-col>
              <v-col :cols=6>
                <v-text-field 
                  v-model="session.addressLine2" 
                  type="text" 
                  label="Session Address Line 2"
                ></v-text-field>
              </v-col>
              <v-col :cols=6>
                <v-text-field 
                  v-model="session.city" 
                  type="text" 
                  label="City"
                  :rules="[rules.city]"
                ></v-text-field>
              </v-col>
              <v-col :cols=3>
                <v-autocomplete
                  v-model="session.state"
                  :items="states"
                  color="white"
                  hide-no-data
                  hide-selected
                  item-text="Description"
                  placeholder="State"
                  :rules="[rules.state]"
                ></v-autocomplete>
              </v-col>
              <v-col :cols=3>
                <v-text-field 
                  v-model="session.zipcode"
                  type="text" 
                  label="Zipcode"
                  :rules="[rules.zipcode]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols=6>
                <v-text-field label="Price" v-model="session.price" type="number"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field label="BookIt Url" v-model="session.bookItUrl"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field label="Title" v-model="session.title"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols=6>
                <v-text-field label="Youngest Age Accepted" v-model="session.ageYoungest" type="number"></v-text-field>
              </v-col>
              <v-col :cols=6>
                <v-text-field label="Oldest Age Accepted" v-model="session.ageOldest" type="number"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols=6>
                <v-text-field label="Camp Start Time" v-model="session.campStartTime" type="time"></v-text-field>
              </v-col>
              <v-col :cols=6>
                <v-text-field label="Camp End Time" v-model="session.campEndTime" type="time"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols=4>
                <v-switch v-model="session.overnight"  class="ma-2" label="Overnight"></v-switch>
              </v-col>
              <v-col :cols=4>
                <v-switch v-model="session.busService"  class="ma-2" label="Bus Service"></v-switch>
              </v-col>
              <v-col :cols=4>
                <v-switch v-model="session.meals"  class="ma-2" label="Meals"></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols=4>
                <v-switch v-model="session.beforeCare" class="ma-2" label="Before Care"></v-switch>
                <v-text-field v-if="session.beforeCare" label="Before Care Start Time" v-model="session.beforeCareStart" type="time"></v-text-field>
              </v-col>
              <v-col :cols=4>
                <v-switch v-model="session.afterCare" class="ma-2" label="After Care"></v-switch>
                <v-text-field v-if="session.afterCare" label="After Care End Time" v-model="session.afterCareEnd" type="time"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-combobox
                  v-model="session.categories"
                  :items="categories"
                  chips
                  clearable
                  label="Session Categories"
                  multiple
                  solo
                >
                  <template v-slot:selection="{ attrs, item, select, selected }">
                    <v-chip
                      v-bind="attrs"
                      :input-value="selected"
                      close
                      @click="select"
                      @click:close="remove(item)"
                    >
                      <strong>{{ item }}</strong>&nbsp;
                    </v-chip>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
              <v-textarea
                  v-model="session.sessionDescription"
                  label="Description"
                  row-height="24"
                  rows="4"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </div>
        <v-row>
          <v-btn
            color="primary"
            class="ml-3 white--text"
            @click="UpdateSession(false)"
            :disabled="!formValid"
          >
            <span v-if="session.draft">Update & Publish</span>
            <span v-else>Update</span>
          </v-btn> 
          <v-btn v-if="session.draft"
            color="primary"
            class="ml-3 white--text"
            @click="UpdateSession(true)"
            :disabled="!formValid"
          >
            <span>Save Draft</span>
          </v-btn> 
          <v-btn
            color="primary"
            class="ml-3 white--text"
            @click="Cancel()"
            :disabled="!formValid"
          >
            <span>Cancel</span>
          </v-btn> 
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import moment from 'moment'
import DaysWidget from '@/components/DaysWidget.vue'
import CampService from '@/api-services/camp.service.js';
import sessionsMixin from '@/mixins/SessionsMixin.js';
import Navbar from '@/components/Navbar.vue';

export default {
  name: 'EditSessionForm',
  components: {
      'DaysWidget': DaysWidget,
      'Navbar': Navbar
  },
  mixins: [sessionsMixin],
  data () {
    return {
      campSessionId: this.$route.params.sessionId,
      session: {},
      formValid: true,
      useCampAddress: false,
      minDate: moment().format('YYYY-MM-DD'),
      maxDate: moment().add('1 month').format('YYYY-MM-DD'),
      categories: [],
      startDateMenu: false,
      endDateMenu: false,
      startDate: this.session?.startDate ? moment(this.session.startDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      endDate: this.session?.endDate ? moment(this.session.endDate).format('YYYY-MM-DD') : moment().add(1, 'weeks').format('YYYY-MM-DD'),
      beforeCareEndUnit: 'am',
      afterCareEndUnit: 'pm',
      dayEvening: [ {name: 'am', value: 'am'},  {name: 'pm', value: 'pm'}],
      localSessionDays: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      },
      address: {
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zipcode: "",
      },
      states: ["AL", "TN", "NC", "KY"],
      rules: {
        addressLine1Rules: value => {
          this.AddressValid()
          return !!value || 'Address is required'
        },
        city: value => {
          this.AddressValid()
          return !!value || 'City is required'
        },
        state: value => {
          this.AddressValid()
          return !!value || 'State is required'
        },
        zipcode: value => {
          this.AddressValid()
          return !!value || 'Zipcode is required'
        }
      }
    }
  },
  computed: {
    campName() {
      return this.$store.state.campName
    }, 
    virtualCamp: {
      get() {
        return this.session.isVirtual == 1 ? true : false
      },
      set(toggleIsVirtual) {
        console.log("Setting Virtual", toggleIsVirtual)
        this.session.isVirtual =  toggleIsVirtual ? 1 : 0
        console.log("Set Virtual", this.session.isVirtual)
      }
    }
  },
  async mounted() {
    const campSession = await CampService.getCampSessionById(this.campSessionId)
    this.session = campSession.data
  },
  methods: {
    remove(item) {
      let index = this.session.categories.findIndex(el => {return el === item})
      this.session.categories.splice(index, 1)
    },
    updateSessionDays(days) {
      this.session.daysMeet = days
    },
    AddressValid() {
      const sessionAddress = {...this.address}
      if(this.address.addressLine1 === "" || this.address.city === "" || this.address.State === "" || this.address.zipcode === "" ){
        this.$emit('updateFormValid', false)
        return false
      } else {
        this.session.address = sessionAddress
        this.$emit('updateSessionAddress', sessionAddress)
        this.$emit('updateFormValid', true)
        return true
      }
    },
    async UpdateSession(publish) {
      this.session.draft =  publish 
      console.log("updating session", this.session)
      const session = this.formatSession(this.session)
      await CampService.updateCampSession(session)
      this.$router.push('/camp')
    },
    Cancel() {
      this.$router.push('/camp')
    }
  },
  watch: {
    startDate(newDate) {
      this.session.startDate = newDate
    },
    endDate(newDate) {
      this.session.endDate = newDate
    },
    session(newSession) {
      this.startDate =  newSession.startDate ? moment(newSession.startDate).format('YYYY-MM-DD') : null
      this.endDate =  newSession.endDate ? moment(newSession.endDate).format('YYYY-MM-DD') : null
      this.session.endDate = newSession.endDate
      this.session.startDate = newSession.startDate
    },
  }
}
</script>

<style scoped>
.form {
  margin: 0 10%;
}

.v-icon.v-icon {
  display: block;
  color: #fff !important;
}

h1 {
  color: #ffffff;
}

</style>
